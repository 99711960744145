export class EnvService {
  public production = false;
  public BASE_URL = undefined; //Added to simplify the http header interceptor logic
  public SUBSCRIPTION_KEY;
  public VERSION: string;
  public FULLSTORY: boolean;
  public SHOWINSTANCEID: boolean;
  public ALLOW_OPERATION_BOARD_BY_TAGS: string[];
  public DIGITAL_ONBOARDING_ADMIN_TAG: string;
  public INSTANCES_REASSIGNMENT_TAG: string;
  public ALLOW_VIEW_INSTANCES_HISTORY_BY_TAGS: string[];
  public ADMINISTRATION_OPTION: { show: boolean, roles: string[] };
  public EXTRA_OPTIONS_MENU: { label: string, external: string }[];
  public SESSION_EXPIRE_TIMEOUT: number;
  public INSTRUMENTATION_KEY: string;
  public ENABLE_AUTO_ROUTE_TRACKING: boolean;
  public LD_KEY: string;
  public MAX_NAVIGATION_ITEMS: number;
  
  public CHARTS: {
    BASE_URL: string,
    SOLICITUDE_LIFE_CYCLE: string,
    DASHBOARD_CHARTS: string[]
  };

  public REPORTS: {
    FILTERS: any[],
  };

  public DIGITAL_ONBOARDING: {
    ADMIN_PANEL: {
      FIELDS: any,
    }
  };

  public INSTANCES_REASSIGNMENT: {
    DATA_PRODUCT: any[]
  };
    
  public APIS = {
    ORIGINATIONS: undefined,
    QUERYABLE: undefined,
    AAD_SYNC: undefined,
    TENANTID: undefined,
    WEBSOCKET: undefined,
    PARTIAL_SAVE_PERMISSION:undefined,
    CHARTS: undefined,
    AGENTS: undefined,
    INSTANCES: undefined,
    INSTANCES_API: undefined,
    COMMENTS: undefined,
    CORE: undefined,
    TENANTS: undefined,
    PARTIAL_SAVE: undefined,
    DIGITAL_ONBOARDING: undefined,
  };
  OFFICE365 = {
    CLIENT_ID: undefined,
    TENANT: undefined,
    REDIRECT: undefined,
    POSTREDIRECT: undefined,
    LOCATION: undefined
  };
  IGNORE_PARTIAL_SAVE_PERMISSION: {
    ON_IMAGES_CONTROLS: boolean
  };

  MSAL = {
    CLIENT_ID: undefined,
    AUTHORITY: undefined,
    SCOPES: undefined
  };
  constructor() {
  }
}
